@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Riffic+Free:wght@400;700&display=swap'); /* Import Riffic Font */

/* Body font styles */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Apply Roboto to all paragraph text */
p {
  @apply font-roboto;
}

/* Apply Poppins to all header text */
h2, h3, h4, h5, h6 {
  @apply font-poppins;
}

/* Apply Nerko One font to h1*/
h1 {
  font-family: 'Nerko One', sans-serif; /* Applying Nerko One */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Scrollbar styles for WebKit browsers (Chrome, Safari, etc.) */
::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Light background for the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Darker background for the scrollbar thumb */
  border-radius: 10px; /* Round the scrollbar edges */
  border: 2px solid #f1f1f1; /* Add space between scrollbar thumb and track */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker shade when hovering over the scrollbar */
}

/* Scrollbar styles for Firefox */
* {
  scrollbar-width: thin; /* Make the scrollbar thin */
  scrollbar-color: #888 #f1f1f1; /* Thumb color followed by track color */
}

/* Optional: Smooth scrolling for a sleek effect */
html {
  scroll-behavior: smooth;
}
